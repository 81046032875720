<template>
    <svg class="" fill="none" :height="height" :width="width" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M.342 12c0 5.94 4.32 10.86 10.02 11.88l.071-.058-.01-.002v-8.46h-3V12h3V9.36c0-3 1.92-4.68 4.68-4.68.84 0 1.8.12 2.64.24v3.06h-1.56c-1.44 0-1.8.72-1.8 1.68V12h3.18l-.54 3.36h-2.64v8.46l-.11.02.05.04c5.7-1.02 10.02-5.94 10.02-11.88 0-6.6-5.4-12-12-12s-12 5.4-12 12Z" :fill="fill" />
    </svg>
</template>
<script>
export default {
    props: {
        fill: {
            type: String,
            default: '#253139',
        },
        height: {
            type: String,
            default: "20px",
        },
        width: {
            type: String,
            default: "20px",
        },
    }
}
</script>
